.App {
  position: relative;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
  height: 100vh;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.App::-webkit-scrollbar {
  display: none;
}

.section {
  width: 100%;
  height: 95vh;
  position: relative;
  text-align: center;
  /*scroll-snap-align: center; TODO po odkomentovani funguje snapovani, ale na PC to je hrozne rychly*/
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
  box-shadow: 0px 10px 29px -5px rgba(0,0,0,0.3);
}

.section.harm {
  height: fit-content !important;
  min-height: 95vh;
}

@media screen and (max-width: 900px) {
  .section {
    height: 85vh;
  }

  .section.harm {
    min-height: 85vh;
  }
}

.section.basic {
  font-family: 'Allura', cursive;
  font-size: 6rem;
  justify-content: space-around;
  padding-top: 15vh;
  flex-direction: column;
}

.announcement {
  font-family: Cardo, serif;
  color: #943214 !important;
  padding: 1rem;
  font-size: medium;
  text-align: left;
  font-style: italic;
  background-color: #FCFDF8;
  max-width: 40rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  position: relative;
  transform: rotate(-1deg);
}

@media screen and (max-width: 600px) {
  .announcement {
    padding: 0.5rem;
    font-size: small;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .kad {
    font-size: 3rem;
  }
}

img.floating {
  /*height: 30vh;*/
  position: absolute;
  z-index: -1;
  max-width: 40%;
}

.section.additional {
  font-size: 1.3rem;
  flex-direction: column;
}

.section.additional div {
  line-height: 2.5rem;
}

q {
  font-style: italic;
}
.arrow {
  animation: bounce 2s ease infinite;
}
@keyframes bounce {
  70% { transform:translateY(0%); }
  80% { transform:translateY(20%); }
  90% { transform:translateY(0%); }
  95% { transform:translateY(10%); }
  97% { transform:translateY(0%); }
  99% { transform:translateY(5%); }
  100% { transform:translateY(0); }
}

a {
  color: inherit;
  font-weight: bold;
}
